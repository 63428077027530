import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@app/core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from '@app/shared/layout/header/header.component';
import { LoaderComponent } from '@app/shared/components/loader/loader.component';
import { LetDirective } from '@ngrx/component';
import { JwtInterceptor } from '@app/core/interceptors/jwt.interceptor';
import { UnauthorizedInterceptor } from '@app/core/interceptors/unauthorized.interceptor';
import { ErrorInterceptor } from "@app/core/interceptors/http.interceptor";
import { DataModule } from '@app/data/data.module';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { StoreModule } from '@ngrx/store';
import { loadingIndicatorReducer } from './core/states/loading-indicator/loading-indicator.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    StoreModule.forRoot({ events: loadingIndicatorReducer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: false, // Restrict extension to log-only mode
    }),
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="btn-spinner-loader"><i class="fa-regular fa-spinner fa-spin"></i></span>',
      disableBtn: true,
      btnLoadingClass: 'is-loading',
      handleCurrentBtnOnly: false,
    }),
    DataModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    HeaderComponent,
    LoaderComponent,
    LetDirective,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
