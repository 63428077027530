import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { Store, StoreModule } from '@ngrx/store';
// import { logout } from '@app/views/auth/state/actions';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'gp-mobile-menu',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    // StoreModule
  ],
  template: `
    <ng-container *ngFor="let menuItem of menuItems">
      <ng-container *ngIf="menuItem.callback else noCallback">
        <button mat-menu-item (click)="menuItem.callback()">
          <mat-icon>{{menuItem.icon}}</mat-icon>
          {{menuItem.label}}
        </button>
      </ng-container>
      <ng-template #noCallback>
        <ng-container *ngIf="menuItem.link">
          <a mat-menu-item [href]="menuItem.link">
            <mat-icon>{{menuItem.icon}}</mat-icon>
            {{menuItem.label}}
          </a>
        </ng-container>
      </ng-template>
    </ng-container>
  `,
  styles: [
  ]
})
export class MobileMenuComponent {
  menuItems: Array<{
    icon: string;
    label: string;
    callback?: () => void;
    link?: string;
  }> = [
    {
      icon: 'logout',
      label: 'Log out',
      callback: this.onLogout.bind(this)
    },
    {
      icon: 'contact_support',
      label: 'Contact us',
      link: '/contact-us'
    },
    {
      icon: 'business',
      label: 'About us',
      link: '/about-us'
    },
  ];

  // constructor(private store: Store) {
  // }

  onLogout() {
    // this.store.dispatch(logout());
  }
}
