import { Component } from '@angular/core';
import { ThemeService } from '@app/core/services/theme.service';
import { LoaderService } from '@app/core/services/loader.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'gp-root',
  template: `
    <div class="content">
      <router-outlet />
    </div>
    <ng-container *ngrxLet="loaderService.loading$ | async as isLoading">
      <gp-loader *ngIf="isLoading"/>
    </ng-container>
  `,
  styles: [ `
    .content {
      width: 100%;
      min-height: 100vh;
      margin: 0 auto;
      background-color: var(--c-neutral-90);
    }

    gp-header {
      z-index: 10;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
  ` ]
})
export class AppComponent {
  constructor(
    private themeService: ThemeService,
    public loaderService: LoaderService,
    private router: Router,
    private authService: AuthService
  ) {
    this.authService.runInitialLoginSequence();
    this.themeService.initByPreferred();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
      }
    });
  }
}
