<button class="profile-link" mat-button [matMenuTriggerFor]="menu">
  <span class="content-wrapper">
    <span class="profile-img">
      <img *ngIf="avatar; else person" [ngSrc]="avatar" [width]="24" [height]="24" alt="User Profile">
      <ng-template #person>
        <mat-icon>person</mat-icon>
      </ng-template>
    </span>
    {{username || 'Account'}}
  </span>
</button>
<mat-menu #menu>
  <ng-container *ngFor="let menuItem of menuItems">
    <button mat-menu-item [routerLink]="menuItem.url">
      <mat-icon>{{menuItem.icon}}</mat-icon>
      <p>{{menuItem.label}}</p>
    </button>
  </ng-container>
</mat-menu>
