import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class LoginGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(): boolean | UrlTree {
    if (this.authService.accessToken) {
      return this.router.createUrlTree(['/cpl'])
    } else {
      return true;
    }
  }
}

export const canActivateLogin: CanActivateFn = () => {
  return inject(LoginGuard).canActivate();
};
