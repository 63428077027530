export const environment = {
  production: false,
  api: {
    url: 'https://gp-cpl-api.nt-development.dev/api/v1'
  },
  website: 'https://cy.linkedin.com/in/gp-hermes-0a7a0b232',
  oAuth2: {
    issuer: 'https://gp-auth-api.nt-development.dev',
    clientId: 'cpl-client',
    dummyClientSecret: 'cpl-secret',
    scope: 'openid roles email profile cpl',
    responseType: 'code',
    requestAccessToken: true,
    showDebugInformation: true,
    disablePKCE: true,
  }
};
