import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@app/shared/components/snackbar/snackbar.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snackbar: MatSnackBar) {}

  intercept(request: HttpRequest<never>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'An error occurred';

        if (error.error instanceof ErrorEvent) {
          // Client-side error
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          // Server-side error
          errorMessage = `Server-side error (status code ${error.status}): ${error.message}`;
          if (Array.isArray(error.error?.error?.details)) {
            if (error.error.error.details[0].message) {
              errorMessage = `Server-side error: ${error.error.error.details[0].message}`;
            }
          }
        }

        this.snackbar.openFromComponent(SnackbarComponent, {
          data: {
            message: errorMessage,
            status: 'error',
            showIcon: true
          },
          duration: 2500,
          horizontalPosition: 'start',
          verticalPosition: 'bottom'
        })

        return throwError(() => error);
      })
    );
  }
}
