import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'gp-profile-link',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule, RouterLink, NgOptimizedImage],
  templateUrl: './profile-link.component.html',
  styleUrls: ['./profile-link.component.scss']
})
export class ProfileLinkComponent {
  @Input() username?: string;
  @Input() avatar?: string;

  menuItems: {
    icon: string;
    label: string;
    url: string;
  }[] = [
    {
      icon: 'login',
      label: 'Log in',
      url: '/auth/login'
    },
    {
      icon: 'touch_app',
      label: 'Sign up',
      url: '#'
    },
    {
      icon: 'history',
      label: 'History',
      url: '#'
    }
  ]
}
