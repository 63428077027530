import {ModuleWithProviders, NgModule, Optional, SkipSelf} from "@angular/core";
import {throwIfAlreadyLoaded} from "@app/core/import.guard";
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    StoreModule.forRoot({}, {}),
  ],
  exports: []
})
export class DataModule {
  constructor(@Optional() @SkipSelf() parentModule: DataModule) {
    throwIfAlreadyLoaded(parentModule, 'DataModule');
  }

  static forRoot(): ModuleWithProviders<DataModule> {
    return {
      ngModule: DataModule,
      providers: []
    }
  }
}
