import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { mergeMap, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (this.authService.getRefreshToken) {
            return this.handleRefreshToken(request, next);
          }
          this.authService.logout();
        }
        return throwError(() => error);
      })
    );
  }

  private handleRefreshToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return fromPromise(this.authService.refreshToken()).pipe(
      mergeMap(tokenRes => {
        if (tokenRes.access_token) {
          request = request.clone({ setHeaders: { Authorization: `Bearer ${tokenRes.access_token}` } })
          return next.handle(request);
        }
        return throwError(() => 'Unauthorized access token');
      }),
      catchError(err => throwError(() => `Error during refresh token. ${err}`))
    );
  }
}
