import { MatPaginatorIntl } from "@angular/material/paginator";

export class MatPaginatorIntlEn extends MatPaginatorIntl {
  override itemsPerPageLabel = 'Rows Page';
  // override nextPageLabel     = 'Next page';
  // override previousPageLabel = 'Previous page';

  // override getRangeLabel = function (page: number, pageSize: number, length: any) {
  //   if (length === 0 || pageSize === 0) {
  //     return '0 od ' + length;
  //   }
  //   length = Math.max(length, 0);
  //   const startIndex = page * pageSize;
  //   // If the start index exceeds the list length, do not try and fix the end index to the end.
  //   const endIndex = startIndex < length ?
  //     Math.min(startIndex + pageSize, length) :
  //     startIndex + pageSize;
  //   return startIndex + 1 + ' - ' + endIndex + ' of ' + length;
  // };

}
