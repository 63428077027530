import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BreadCrumb } from './shared/components/breadcrumb/bread-crumb';
import { canActivateLanding } from '@app/core/services/auth.guard';
import { canActivateLogin } from '@app/core/services/login.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'login',
    loadComponent: () => import('./views/login/login.component'),
    canActivate: [canActivateLogin],
  },
  {
    path: 'cpl',
    loadChildren: () => import('./views/cpl/cpl.module')
      .then(m => m.CplModule),
      data: {
        breadcrumb: {
          icon: 'home',
          iconOnly: true,
          extraIconStyleClass: 'material-icons-outlined',
        } as BreadCrumb,
      },
    canActivate: [canActivateLanding],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
