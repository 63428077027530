import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from './import.guard';
import { ThemeService } from './services/theme.service';
import { AuthService } from './services/auth.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpService } from './services/http.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { createPaginatorLabel } from './adapter/paginator-label-creator';
import { AuthConfig, OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { authConfig } from '@app/core/services/auth-config';
import { authModuleConfig } from '@app/core/services/auth-module-config';
import { AuthGuard } from '@app/core/services/auth.guard';
import { AuthGuardWithForcedLogin } from '@app/core/services/auth-guard-with-forced-login.service';
import { HttpClientModule } from '@angular/common/http';
import { LoginGuard } from '@app/core/services/login.guard';

@NgModule({
  imports: [
    MatDialogModule,
    MatSnackBarModule,
    HttpClientModule,
    OAuthModule.forRoot()
  ],
  exports: [],
  providers: [
    ThemeService,
    AuthService,
    HttpService,
    AuthGuard,
    AuthGuardWithForcedLogin,
    LoginGuard
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ThemeService,
        AuthService,
        HttpService,
        { provide: MatPaginatorIntl, useFactory: createPaginatorLabel },
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig }
      ]
    } as ModuleWithProviders<CoreModule>;
  }
}
