import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(): boolean | UrlTree {
    if (this.authService.accessToken) {
      return true;
    } else {
      return this.router.createUrlTree(['/login'])
    }
  }
}

export const canActivateLanding: CanActivateFn = () => {
  return inject(AuthGuard).canActivate();
};
