import { HttpContextToken } from "@angular/common/http";

export class Constants {
    // --------------------Calendar constants properties
    public static readonly CALENDAR_YEAR_INDEX = 0;
    public static readonly CALENDAR_MONTH_INDEX = 1;
    public static readonly CALENDAR_DAY_INDEX = 2;
    // -------------------------------------------------
    // --------------------Pagination constants properties
    public static readonly PAGINATION_ROWS_COUNTS = [10, 20, 50, 100];
    // ---------------------------------------------------
    // --------------------Http Context Tokens
    public static TOKEN_REQUIRED = new HttpContextToken(() => true);
    // ---------------------------------------------------
    public static COUNTRY_CODES = [
        { text: '🇺🇸 +1   United States', code: '+1', country: 'US' },
        { text: '🇨🇦 +1   Canada', code: '+1', country: 'CA' },
        { text: '🇷🇺 +7   Russia', code: '+7', country: 'RU' },
        { text: '🇪🇬 +20  Egypt', code: '+20', country: 'EG' },
        { text: '🇿🇦 +27  South Africa', code: '+27', country: 'ZA' },
        { text: '🇬🇷 +30  Greece', code: '+30', country: 'GR' },
        { text: '🇳🇱 +31  Netherlands', code: '+31', country: 'NL' },
        { text: '🇧🇪 +32  Belgium', code: '+32', country: 'BE' },
        { text: '🇫🇷 +33  France', code: '+33', country: 'FR' },
        { text: '🇪🇸 +34  Spain', code: '+34', country: 'ES' },
        { text: '🇭🇺 +36  Hungary', code: '+36', country: 'HU' },
        { text: '🇮🇹 +39  Italy', code: '+39', country: 'IT' },
        { text: '🇷🇴 +40  Romania', code: '+40', country: 'RO' },
        { text: '🇨🇭 +41  Switzerland', code: '+41', country: 'CH' },
        { text: '🇦🇹 +43  Austria', code: '+43', country: 'AT' },
        { text: '🇬🇧 +44  United Kingdom', code: '+44', country: 'GB' },
        { text: '🇩🇰 +45  Denmark', code: '+45', country: 'DK' },
        { text: '🇸🇪 +46  Sweden', code: '+46', country: 'SE' },
        { text: '🇳🇴 +47  Norway', code: '+47', country: 'NO' },
        { text: '🇵🇱 +48  Poland', code: '+48', country: 'PL' },
        { text: '🇩🇪 +49  Germany', code: '+49', country: 'DE' },
        { text: '🇵🇪 +51  Peru', code: '+51', country: 'PE' },
        { text: '🇲🇽 +52  Mexico', code: '+52', country: 'MX' },
        { text: '🇨🇺 +53  Cuba', code: '+53', country: 'CU' },
        { text: '🇦🇷 +54  Argentina', code: '+54', country: 'AR' },
        { text: '🇧🇷 +55  Brazil', code: '+55', country: 'BR' },
        { text: '🇨🇱 +56  Chile', code: '+56', country: 'CL' },
        { text: '🇨🇴 +57  Colombia', code: '+57', country: 'CO' },
        { text: '🇻🇪 +58  Venezuela', code: '+58', country: 'VE' },
        { text: '🇲🇾 +60  Malaysia', code: '+60', country: 'MY' },
        { text: '🇦🇺 +61  Australia', code: '+61', country: 'AU' },
        { text: '🇮🇩 +62  Indonesia', code: '+62', country: 'ID' },
        { text: '🇵🇭 +63  Philippines', code: '+63', country: 'PH' },
        { text: '🇳🇿 +64  New Zealand', code: '+64', country: 'NZ' },
        { text: '🇸🇬 +65  Singapore', code: '+65', country: 'SG' },
        { text: '🇹🇭 +66  Thailand', code: '+66', country: 'TH' },
        { text: '🇯🇵 +81  Japan', code: '+81', country: 'JP' },
        { text: '🇰🇷 +82  South Korea', code: '+82', country: 'KR' },
        { text: '🇻🇳 +84  Vietnam', code: '+84', country: 'VN' },
        { text: '🇨🇳 +86  China', code: '+86', country: 'CN' },
        { text: '🇹🇷 +90  Turkey', code: '+90', country: 'TR' },
        { text: '🇮🇳 +91  India', code: '+91', country: 'IN' },
        { text: '🇵🇰 +92  Pakistan', code: '+92', country: 'PK' },
        { text: '🇦🇫 +93  Afghanistan', code: '+93', country: 'AF' },
        { text: '🇱🇰 +94  Sri Lanka', code: '+94', country: 'LK' },
        { text: '🇲🇲 +95  Myanmar', code: '+95', country: 'MM' },
        { text: '🇮🇷 +98  Iran', code: '+98', country: 'IR' },
        { text: '🇲🇦 +212 Morocco', code: '+212', country: 'MA' },
        { text: '🇩🇿 +213 Algeria', code: '+213', country: 'DZ' },
        { text: '🇹🇳 +216 Tunisia', code: '+216', country: 'TN' },
        { text: '🇱🇾 +218 Libya', code: '+218', country: 'LY' },
        { text: '🇬🇲 +220 Gambia', code: '+220', country: 'GM' },
        { text: '🇸🇳 +221 Senegal', code: '+221', country: 'SN' },
        { text: '🇲🇷 +222 Mauritania', code: '+222', country: 'MR' },
        { text: '🇲🇱 +223 Mali', code: '+223', country: 'ML' },
        { text: '🇬🇳 +224 Guinea', code: '+224', country: 'GN' },
        { text: '🇨🇮 +225 Ivory Coast', code: '+225', country: 'CI' },
        { text: '🇧🇫 +226 Burkina Faso', code: '+226', country: 'BF' },
        { text: '🇳🇪 +227 Niger', code: '+227', country: 'NE' },
        { text: '🇹🇬 +228 Togo', code: '+228', country: 'TG' },
        { text: '🇧🇯 +229 Benin', code: '+229', country: 'BJ' },
        { text: '🇲🇺 +230 Mauritius', code: '+230', country: 'MU' },
        { text: '🇱🇷 +231 Liberia', code: '+231', country: 'LR' },
        { text: '🇸🇱 +232 Sierra Leone', code: '+232', country: 'SL' },
        { text: '🇬🇭 +233 Ghana', code: '+233', country: 'GH' },
        { text: '🇳🇬 +234 Nigeria', code: '+234', country: 'NG' },
        { text: '🇹🇩 +235 Chad', code: '+235', country: 'TD' },
        { text: '🇨🇫 +236 Central African Republic', code: '+236', country: 'CF' },
        { text: '🇨🇲 +237 Cameroon', code: '+237', country: 'CM' },
        { text: '🇨🇻 +238 Cape Verde', code: '+238', country: 'CV' },
        { text: '🇸🇹 +239 Sao Tome and Principe', code: '+239', country: 'ST' },
        { text: '🇬🇶 +240 Equatorial Guinea', code: '+240', country: 'GQ' },
        { text: '🇬🇦 +241 Gabon', code: '+241', country: 'GA' },
        { text: '🇨🇬 +242 Republic of the Congo', code: '+242', country: 'CG' },
        { text: '🇨🇩 +243 Democratic Republic of the Congo', code: '+243', country: 'CD' },
        { text: '🇦🇴 +244 Angola', code: '+244', country: 'AO' },
        { text: '🇬🇼 +245 Guinea-Bissau', code: '+245', country: 'GW' },
        { text: '🇮🇴 +246 British Indian Ocean Territory', code: '+246', country: 'IO' },
        { text: '🇸🇨 +248 Seychelles', code: '+248', country: 'SC' },
        { text: '🇸🇩 +249 Sudan', code: '+249', country: 'SD' },
        { text: '🇷🇼 +250 Rwanda', code: '+250', country: 'RW' },
        { text: '🇪🇹 +251 Ethiopia', code: '+251', country: 'ET' },
        { text: '🇸🇴 +252 Somalia', code: '+252', country: 'SO' },
        { text: '🇩🇯 +253 Djibouti', code: '+253', country: 'DJ' },
        { text: '🇰🇪 +254 Kenya', code: '+254', country: 'KE' },
        { text: '🇹🇿 +255 Tanzania', code: '+255', country: 'TZ' },
        { text: '🇺🇬 +256 Uganda', code: '+256', country: 'UG' },
        { text: '🇧🇮 +257 Burundi', code: '+257', country: 'BI' },
        { text: '🇲🇿 +258 Mozambique', code: '+258', country: 'MZ' },
        { text: '🇿🇲 +260 Zambia', code: '+260', country: 'ZM' },
        { text: '🇲🇬 +261 Madagascar', code: '+261', country: 'MG' },
        { text: '🇷🇪 +262 Reunion', code: '+262', country: 'RE' },
        { text: '🇿🇼 +263 Zimbabwe', code: '+263', country: 'ZW' },
        { text: '🇳🇦 +264 Namibia', code: '+264', country: 'NA' },
        { text: '🇲🇼 +265 Malawi', code: '+265', country: 'MW' },
        { text: '🇱🇸 +266 Lesotho', code: '+266', country: 'LS' },
        { text: '🇧🇼 +267 Botswana', code: '+267', country: 'BW' },
        { text: '🇸🇿 +268 Eswatini', code: '+268', country: 'SZ' },
        { text: '🇰🇲 +269 Comoros', code: '+269', country: 'KM' },
        { text: '🇸🇭 +290 Saint Helena', code: '+290', country: 'SH' },
        { text: '🇪🇷 +291 Eritrea', code: '+291', country: 'ER' },
        { text: '🇦🇼 +297 Aruba', code: '+297', country: 'AW' },
        { text: '🇫🇴 +298 Faroe Islands', code: '+298', country: 'FO' },
        { text: '🇬🇱 +299 Greenland', code: '+299', country: 'GL' },
        { text: '🇬🇮 +350 Gibraltar', code: '+350', country: 'GI' },
        { text: '🇵🇹 +351 Portugal', code: '+351', country: 'PT' },
        { text: '🇱🇺 +352 Luxembourg', code: '+352', country: 'LU' },
        { text: '🇮🇪 +353 Ireland', code: '+353', country: 'IE' },
        { text: '🇮🇸 +354 Iceland', code: '+354', country: 'IS' },
        { text: '🇦🇱 +355 Albania', code: '+355', country: 'AL' },
        { text: '🇲🇹 +356 Malta', code: '+356', country: 'MT' },
        { text: '🇨🇾 +357 Cyprus', code: '+357', country: 'CY' },
        { text: '🇫🇮 +358 Finland', code: '+358', country: 'FI' },
        { text: '🇧🇬 +359 Bulgaria', code: '+359', country: 'BG' },
        { text: '🇱🇹 +370 Lithuania', code: '+370', country: 'LT' },
        { text: '🇱🇻 +371 Latvia', code: '+371', country: 'LV' },
        { text: '🇪🇪 +372 Estonia', code: '+372', country: 'EE' },
        { text: '🇲🇩 +373 Moldova', code: '+373', country: 'MD' },
        { text: '🇦🇲 +374 Armenia', code: '+374', country: 'AM' },
        { text: '🇧🇾 +375 Belarus', code: '+375', country: 'BY' },
        { text: '🇦🇩 +376 Andorra', code: '+376', country: 'AD' },
        { text: '🇲🇨 +377 Monaco', code: '+377', country: 'MC' },
        { text: '🇸🇲 +378 San Marino', code: '+378', country: 'SM' },
        { text: '🇺🇦 +380 Ukraine', code: '+380', country: 'UA' },
        { text: '🇷🇸 +381 Serbia', code: '+381', country: 'RS' },
        { text: '🇲🇪 +382 Montenegro', code: '+382', country: 'ME' },
        { text: '🇭🇷 +385 Croatia', code: '+385', country: 'HR' },
        { text: '🇸🇮 +386 Slovenia', code: '+386', country: 'SI' },
        { text: '🇧🇦 +387 Bosnia and Herzegovina', code: '+387', country: 'BA' },
        { text: '🇲🇰 +389 North Macedonia', code: '+389', country: 'MK' },
        { text: '🇨🇿 +420 Czech Republic', code: '+420', country: 'CZ' },
        { text: '🇸🇰 +421 Slovakia', code: '+421', country: 'SK' },
        { text: '🇱🇮 +423 Liechtenstein', code: '+423', country: 'LI' },
        { text: '🇫🇰 +500 Falkland Islands', code: '+500', country: 'FK' },
        { text: '🇧🇿 +501 Belize', code: '+501', country: 'BZ' },
        { text: '🇬🇹 +502 Guatemala', code: '+502', country: 'GT' },
        { text: '🇸🇻 +503 El Salvador', code: '+503', country: 'SV' },
        { text: '🇭🇳 +504 Honduras', code: '+504', country: 'HN' },
        { text: '🇳🇮 +505 Nicaragua', code: '+505', country: 'NI' },
        { text: '🇨🇷 +506 Costa Rica', code: '+506', country: 'CR' },
        { text: '🇵🇦 +507 Panama', code: '+507', country: 'PA' },
        { text: '🇵🇲 +508 Saint Pierre and Miquelon', code: '+508', country: 'PM' },
        { text: '🇭🇹 +509 Haiti', code: '+509', country: 'HT' },
        { text: '🇬🇵 +590 Guadeloupe', code: '+590', country: 'GP' },
        { text: '🇧🇴 +591 Bolivia', code: '+591', country: 'BO' },
        { text: '🇬🇾 +592 Guyana', code: '+592', country: 'GY' },
        { text: '🇪🇨 +593 Ecuador', code: '+593', country: 'EC' },
        { text: '🇬🇫 +594 French Guiana', code: '+594', country: 'GF' },
        { text: '🇵🇾 +595 Paraguay', code: '+595', country: 'PY' },
        { text: '🇲🇶 +596 Martinique', code: '+596', country: 'MQ' },
        { text: '🇸🇷 +597 Suriname', code: '+597', country: 'SR' },
        { text: '🇺🇾 +598 Uruguay', code: '+598', country: 'UY' },
        { text: '🇨🇼 +599 Curaçao', code: '+599', country: 'CW' },
        { text: '🇹🇱 +670 Timor-Leste', code: '+670', country: 'TL' },
        { text: '🇦🇶 +672 Antarctica', code: '+672', country: 'AQ' },
        { text: '🇧🇳 +673 Brunei', code: '+673', country: 'BN' },
        { text: '🇳🇷 +674 Nauru', code: '+674', country: 'NR' },
        { text: '🇵🇬 +675 Papua New Guinea', code: '+675', country: 'PG' },
        { text: '🇹🇴 +676 Tonga', code: '+676', country: 'TO' },
        { text: '🇸🇧 +677 Solomon Islands', code: '+677', country: 'SB' },
        { text: '🇻🇺 +678 Vanuatu', code: '+678', country: 'VU' },
        { text: '🇫🇯 +679 Fiji', code: '+679', country: 'FJ' },
        { text: '🇵🇼 +680 Palau', code: '+680', country: 'PW' },
        { text: '🇼🇫 +681 Wallis and Futuna', code: '+681', country: 'WF' },
        { text: '🇨🇰 +682 Cook Islands', code: '+682', country: 'CK' },
        { text: '🇳🇺 +683 Niue', code: '+683', country: 'NU' },
        { text: '🇼🇸 +685 Samoa', code: '+685', country: 'WS' },
        { text: '🇰🇮 +686 Kiribati', code: '+686', country: 'KI' },
        { text: '🇳🇨 +687 New Caledonia', code: '+687', country: 'NC' },
        { text: '🇹🇻 +688 Tuvalu', code: '+688', country: 'TV' },
        { text: '🇵🇫 +689 French Polynesia', code: '+689', country: 'PF' },
        { text: '🇹🇰 +690 Tokelau', code: '+690', country: 'TK' },
        { text: '🇫🇲 +691 Federated States of Micronesia', code: '+691', country: 'FM' },
        { text: '🇲🇭 +692 Marshall Islands', code: '+692', country: 'MH' },
        { text: '🇰🇵 +850 North Korea', code: '+850', country: 'KP' },
        { text: '🇭🇰 +852 Hong Kong', code: '+852', country: 'HK' },
        { text: '🇲🇴 +853 Macau', code: '+853', country: 'MO' },
        { text: '🇰🇭 +855 Cambodia', code: '+855', country: 'KH' },
        { text: '🇱🇦 +856 Laos', code: '+856', country: 'LA' },
        { text: '🇧🇩 +880 Bangladesh', code: '+880', country: 'BD' },
        { text: '🇹🇼 +886 Taiwan', code: '+886', country: 'TW' },
        { text: '🇲🇻 +960 Maldives', code: '+960', country: 'MV' },
        { text: '🇱🇧 +961 Lebanon', code: '+961', country: 'LB' },
        { text: '🇯🇴 +962 Jordan', code: '+962', country: 'JO' },
        { text: '🇸🇾 +963 Syria', code: '+963', country: 'SY' },
        { text: '🇮🇶 +964 Iraq', code: '+964', country: 'IQ' },
        { text: '🇰🇼 +965 Kuwait', code: '+965', country: 'KW' },
        { text: '🇸🇦 +966 Saudi Arabia', code: '+966', country: 'SA' },
        { text: '🇾🇪 +967 Yemen', code: '+967', country: 'YE' },
        { text: '🇴🇲 +968 Oman', code: '+968', country: 'OM' },
        { text: '🇵🇸 +970 Palestine', code: '+970', country: 'PS' },
        { text: '🇦🇪 +971 United Arab Emirates', code: '+971', country: 'AE' },
        { text: '🇮🇱 +972 Israel', code: '+972', country: 'IL' },
        { text: '🇧🇭 +973 Bahrain', code: '+973', country: 'BH' },
        { text: '🇶🇦 +974 Qatar', code: '+974', country: 'QA' },
        { text: '🇧🇹 +975 Bhutan', code: '+975', country: 'BT' },
        { text: '🇲🇳 +976 Mongolia', code: '+976', country: 'MN' },
        { text: '🇳🇵 +977 Nepal', code: '+977', country: 'NP' },
        { text: '🇹🇯 +992 Tajikistan', code: '+992', country: 'TJ' },
        { text: '🇹🇲 +993 Turkmenistan', code: '+993', country: 'TM' },
        { text: '🇦🇿 +994 Azerbaijan', code: '+994', country: 'AZ' },
        { text: '🇬🇪 +995 Georgia', code: '+995', country: 'GE' },
        { text: '🇰🇬 +996 Kyrgyzstan', code: '+996', country: 'KG' },
        { text: '🇺🇿 +998 Uzbekistan', code: '+998', country: 'UZ' },
    ];
}