<mat-toolbar>
  <mat-toolbar-row>
    <gp-hermes-logo />
    <div class="menu-container">
      <div class="menu-container__actions">
        <div class="md-only">
          <button id="menu-btn" mat-icon-button [matMenuTriggerFor]="mobileMenu">
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #mobileMenu>
            <gp-mobile-menu />
          </mat-menu>
        </div>
        <div class="lg-only">
          <gp-nav-links [links]="links" />
          <gp-profile-link [username]="user?.username" [avatar]="user?.avatar" />
        </div>
        <button mat-icon-button (click)="themeService.toggleMode()">
          <mat-icon>{{(themeService.lightMode() | async) ? 'dark_mode' : 'light_mode'}}</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
