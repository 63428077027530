/* eslint-disable @ngrx/on-function-explicit-return-type */
import { createReducer, on } from "@ngrx/store";
import { addGroupSlugs, addSlug, deleteFinishedSlugs, deletePendingSlugs, deleteSlug } from "./loading-indicator.action";

export const initialState: string[] = [];

export const loadingIndicatorReducer = createReducer(
  initialState,
  on(addSlug, (state, { slug }) => state.includes(slug) ? state : [...state, slug]),
  on(deleteSlug, (state, { slug }) => state.filter(p => p != slug)),
  on(addGroupSlugs, (state, { slugs }) => [...state, ...slugs]),
  on(deletePendingSlugs, (state, { functionName }) => state.filter(p => !p.includes(functionName) && !p.includes(':'))),
  on(deleteFinishedSlugs, (state, { functionName }) => state.filter(p => !(p.includes(functionName) && p.includes(':') && !p.includes('$'))).map(m => m.includes('$') ? m.split(':')[0] : m)),
);