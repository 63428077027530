import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'environments';

const CONF = environment.oAuth2;

export const authConfig: AuthConfig = {
  issuer: CONF.issuer,
  clientId: CONF.clientId,
  dummyClientSecret: CONF.dummyClientSecret,
  scope: CONF.scope,
  responseType: CONF.responseType,
  requestAccessToken: CONF.requestAccessToken,
  redirectUri: window.location.origin,
  showDebugInformation: CONF.showDebugInformation,
  disablePKCE: CONF.disablePKCE
};
