import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { HermesLogoComponent } from './hermes-logo/hermes-logo.component';
import { NavLinksComponent } from './nav-links/nav-links.component';
import { ProfileLinkComponent } from './profile-link/profile-link.component';
import { ThemeService } from '@app/core/services/theme.service';
import { MatMenuModule } from '@angular/material/menu';
import { MobileMenuComponent } from '@app/shared/layout/header/mobile-menu/mobile-menu.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'gp-header',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatIconModule, MatButtonModule, HermesLogoComponent, NavLinksComponent, ProfileLinkComponent, MatMenuModule, MobileMenuComponent],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() withSearchbar = false;
  @Input() user?: {username: string; avatar: string};
  @Input() links = this.defaultLinks();

  @Input() searchValue?: string;
  @Output() searchValueChange = new EventEmitter<string>();

  constructor(public themeService: ThemeService) {
  }

  private defaultLinks() {
    return [
      {
        label: 'Tours',
        url: '/tours/cyprus'
      },
      {
        label: 'Travel Guides',
        url: '#'
      },
      {
        label: 'About us',
        url: '#'
      }
    ]
  }
}
