import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'gp-nav-links',
  standalone: true,
  imports: [CommonModule, MatButtonModule, RouterLink, RouterLinkActive],
  template: `
    <ng-container *ngFor="let link of links; index as i">
      <button mat-flat-button [routerLink]="link.url" [routerLinkActive]="'active'">{{link.label}}</button>
    </ng-container>
  `,
  styles: [`
    .active {
      border-bottom: 3px solid var(--c-primary-40);
    }
    button {
      background-color: rgba(0,0,0,0) !important;
      border-radius: 0;
    }
  `]
})
export class NavLinksComponent {
  @Input() links!: {label: string; url: string;}[];
}
