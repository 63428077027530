import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'gp-hermes-logo',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  template: `<img ngSrc="/assets/images/gp_hermes_logo.webp" [width]="125" [height]="30" alt="GP Hermes Logo" />`
})
export class HermesLogoComponent {

}
